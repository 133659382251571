module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Get Rates Now","short_name":"Get Rates Now","start_url":"/","background_color":"#FFFFFF","theme_color":"#222c69","icon":"src/images/logo/flag-favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"46ff6dd88345567c1c72f98d4a0926a5"},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://717f69593c964138a04ad177a4ca60db@o474053.ingest.sentry.io/5509752","environment":"production","release":"080323"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
